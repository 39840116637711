export const routes = [
    {
        path: '/measurements',
        name: 'measurements.browse',
        component: () => import(/* webpackChunkName: "MeasurementsBrowse" */ '@/views/app/MeasurementsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/measurements/:id/clone',
        name: 'measurements.clone',
        component: () => import(/* webpackChunkName: "MeasurementsActions" */ '@/views/app/MeasurementsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/measurements/create',
        name: 'measurements.create',
        component: () => import(/* webpackChunkName: "MeasurementsActions" */ '@/views/app/MeasurementsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/measurements/:id/edit',
        name: 'measurements.edit',
        component: () => import(/* webpackChunkName: "MeasurementsActions" */ '@/views/app/MeasurementsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/measurements/:id/delete',
        name: 'measurements.delete',
        component: () => import(/* webpackChunkName: "MeasurementsActions" */ '@/views/app/MeasurementsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/measurements/:id/show',
        name: 'measurements.show',
        component: () => import(/* webpackChunkName: "MeasurementsActions" */ '@/views/app/MeasurementsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]